import React, { useState } from "react";
import { FormHelperText, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { COMPLETION_BACKEND_URL } from "../Config";
import { useTranslation } from "react-i18next";

const fetchCompletion = async (term, lang, url) => {
    const response = await fetch(COMPLETION_BACKEND_URL + "/" + url, {
        method: "POST",
        body: JSON.stringify({ term, lang }),
        headers: {
            "Content-Type": "application/json",
        },
    });
    return response;
};

export const Completer = ({
    label : rawLabel,
    url,
    helperText,
    id,
    updateInput,
    required,
    multiple,
    className,
    freeSolo,
    dontShowHelper
}) => {
    const { i18n } = useTranslation()
    const handleInputChange = async (e) => {
        const term = e.target.value;
        const response = await fetchCompletion(term, i18n.language, url);
        if (response.ok) {
            const newOptions = await response.json();
            setCurrentOptions(newOptions);
        }
    };
    const [currentOptions, setCurrentOptions] = useState([]);
    const handleOnChange = (e, value) => {
        updateInput(value);
    };
    const { t } = useTranslation()
    const tn = (key) => t(key, { ns: "PaperRx" })
    const label = required ? rawLabel + " *" : rawLabel
    return (
        <Autocomplete
            multiple={multiple}
            options={currentOptions}
            autoSelect={true}
            clearOnEscape={true}
            noOptionsText={tn("noOption")}
            freeSolo={freeSolo}
            onInputChange={handleInputChange}
            onChange={handleOnChange}
            disableCloseOnSelect={false}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderInput={(params) => (
                <div style={{ width: "100%" }}
                    className={className}>
                    <TextField
                        {...params}
                        label={label}
                        id={id}
                        name={id}
                        variant="outlined"
                    />
                    {!dontShowHelper && <FormHelperText>{helperText}</FormHelperText>}
                </div>
            )}
        />
    );
};
