import React, {useState} from "react";
import { Button, TextField, Typography } from "@mui/material";
import { NER_BACKEND_URL } from "../Config";
import TaggyJr from "react-taggy-jr"
const submitNER = async (text) => {
    const response = await fetch(NER_BACKEND_URL+'/'+`ner?text=${text}`)
    if (!response.ok){
        return [null, false]
    } else {
        const data = await response.json()
        return [data,true]
    }
}
const colors = [
    [255,75,75],
    [255,35,164],
    [255,53,227],
    [33,195,84],
    [0,212,177],
    [0,192,242],
].map(arr => ({r : arr[0],g:arr[1],b:arr[2]}))
const colorsType = {
    'substance' : colors[0],
    'strength' : colors[1],
    'drug' : colors[2],
    'form' : colors[3],
    'component' : colors[4],
    'distinction' : colors[5],
    'roa' : colors[6]
}
const isString = (object) => typeof object === 'string' || object instanceof String
const NERVisualizer = ({annotation}) => {
    return annotation.map(annotationEl => {
        if (isString(annotationEl)){
            return annotationEl
        }
        const [text, type] = annotationEl
        return <TaggyJr text={text} type={type} color={colorsType[type]}/>
    })
}
const NERVisualizers = ({annotations}) => {
    return (
        <div>
            <Typography paddingTop={4} paddingBottom={2}>Results</Typography>
	 {annotations.length === 0 && "No entities were detected."}
        {annotations.map(annotation => <div style={{marginBottom : "1rem"}}><NERVisualizer annotation={annotation}/></div>)}
        </div>
    )
}
export function NERPlayground() {
    const [data,setData] = useState()
    const [hasError,setHasError] = useState(false)
    const [hasData, setHasData] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault()
        const form = new FormData(e.target)
        const text = form.get('text')
        const [data,isOk] = await submitNER(text)
        setHasError(!isOk)
        setData(data)
        setHasData(isOk)
    }
    return (
        <div style={{ width: "80%", margin: "auto" }}>
            <form onSubmit={handleSubmit}>
                <Typography paddingBottom={3}>Enter your text below</Typography>
                <TextField
                    multiline={true}
                    name="text"
                    label="Prediction"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                ></TextField>
                <div style={{ marginTop: "1rem" }}>
                    <Button type="submit" variant="contained">
                        Submit
                    </Button>
                </div>
            </form>
            {hasData && (
                <NERVisualizers annotations={data.annotation}/>
            )}
        </div>
    );
}
export default NERPlayground;
