import React from "react"
import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import { Link } from "@material-ui/core"
export const NotFound = () => {
  const {t} = useTranslation()
  const homeLink = <Link href="/"></Link>
  const aboutLink = <Link href="/About"></Link>
  return (
    <div style={{textAlign : "center"}}>
      <Typography variant="h3">
      {t("notfound")}
      </Typography>
      <br/>
      <Typography variant="body">
        <Trans i18nKey="notfoundadd" components={{home : homeLink, about : aboutLink}}></Trans>
      </Typography>
    </div>
  )
}
