import { BACKEND_URl } from "../Config"
export async function submitSparql(query) {
    const url = BACKEND_URl + '/rest/SPARQL'
    const formData = new FormData()
    formData.append("query",encodeURI(query))
    const resp = await fetch(url, {
        method : 'POST',
        body : formData,
        redirect : 'follow'
    })
    if (!resp.ok) return [{}, false]
    const data = await resp.json()
    return [data, true]
}
