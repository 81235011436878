import {USER_MANAGEMENT_URL} from "../Config"
export async function getUserInfo(email){
    const resp = await fetch(USER_MANAGEMENT_URL + '/get_user', {
        method : 'POST',
        body : JSON.stringify({email}),
        headers : {
            'Content-Type' : 'application/json'
        }
    })
    if (!resp.ok){
        return [{},false]
    } else {
        const data = await resp.json()
        return [data, true]
    }
}

export async function submitAccessRequest(info){
    const resp = await fetch(USER_MANAGEMENT_URL + '/user', {
        method : 'POST',
        body : JSON.stringify(info),
        headers : {
            'Content-Type' : 'application/json'
        }
    })
    return resp.ok
}
export async function submitAddDrugRequest(info){
    const resp = await fetch(USER_MANAGEMENT_URL + '/drug', {
        method : 'POST',
        body : JSON.stringify(info),
        headers : {
            'Content-Type' : 'application/json'
        }
    })
    return resp.ok
}