import React from "react"
import {Banner} from "./Banner"
import {OcrForm} from "./Form"
import {useState} from "react"
import {Error}  from "./Error"
import {Results} from "./Results"
import CssBaseline from '@mui/material/CssBaseline';
export const PaperRx = () => {
  const [analysed,setAnalysed] = useState(false)
  const [analysisData, setAnalysisData] = useState()
  const [error,setError] = useState(false)
  return (
    <>
    <CssBaseline />
    <Banner></Banner>
      <OcrForm onAnalysis={([analysisData,isOk]) => {
        setError(isOk)
        setAnalysisData(analysisData)
        setAnalysed(true)
      }}/>
      {analysed && <Results data={analysisData}/>}
      {error && <Error text={"Sorry, something went wrong. Please try again"}/>}
    </>
  )
}
