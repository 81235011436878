import { Card, Typography } from "@mui/material";
import React from "react";

export default function DownloadPage() {
    const linkNames = ["OCRx (full release)", "OCRx (marketed drugs only)", "OCRx without labels (marketed drugs only)"];
    const linkDownloads = ["full", "marketed", "withoutannotation"];
    return (
        <div style={{ maxWidth: "900px", width: "100%", margin: "auto", lineHeight: "1.5" }}>
            <Typography variant="h4" style={{ textAlign: "center", marginBottom : "1em" }}>
                Download OCRx
            </Typography>
            <Typography variant="body">
                You can download the ontologies by clicking the link below.
                There are different versions available for your needs.
                <ul>
                    {linkNames.map((el, index) => {
                        return (
                            <li key={index}>
                                <a href={`https://completion.ocrx.umontreal.ca/download/${linkDownloads[index]}`} download>Download {el}</a>
                            </li>
                        );
                    })}
                </ul>
            </Typography>
        </div>
    );
}
