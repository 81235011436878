import React from "react"
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  label : {
    fontWeight : 800,
    fontSize : "3rem",
    marginBottom : "1.5rem",
  }, 
  container : {
    display : "flex",
    alignItems : "center",
    flexDirection : "column",
    justifyContent : "center",
    textAlign : "center",
    width : "90%",
    margin : "auto",
    //background : "teal",
    // height : "20vmin",
    marginBottom : "5rem",
  },
  labelDescription : {
    fontSize : "1.5rem",

  }
})
export const Banner = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <div className={classes.container}>
      <div className={classes.label}>{t("mainTitle")}</div>
      <div className={classes.labelDescription}>{t("mainDescription")}</div>
    </div>
  )
}
