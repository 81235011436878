import React, {useState, useEffect} from "react"
import { Alert, AlertTitle } from '@mui/material';
import Collapse from "@mui/material/Collapse"

export const Error = ({text}) => {
  const [open,setOpen] = useState(true)
  const delay = 3000
  useEffect(()=>{
    setTimeout(()=>{
      setOpen(false)
    },delay)
  },[])
  return (
    <Collapse in={open}>
      <Alert severity="error">
        <AlertTitle> Error </AlertTitle>
        {text}
      </Alert>
    </Collapse>
  )
}
