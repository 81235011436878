const isProduction = !(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
// const isProduction = false
const completionProductionBackendUrl = "https://ocrx.umontreal.ca"
const completionDevBackendUrl = "http://127.0.0.1:5000"
export const COMPLETION_BACKEND_URL = isProduction? completionProductionBackendUrl : completionDevBackendUrl
const userManagementDevBackendUrl = "http://127.0.0.1:5000"
const userManagementProductionBackendUrl = "https://ocrx.umontreal.ca"
export const USER_MANAGEMENT_URL = isProduction? userManagementProductionBackendUrl : userManagementDevBackendUrl


const productionBackendUrl = "https://ocrx.umontreal.ca"
const devBackendUrl = "http://127.0.0.1:8080"
export const BACKEND_URl = isProduction ? productionBackendUrl : devBackendUrl
export const NER_BACKEND_URL = BACKEND_URl


export const ROUTE_GET_ELEMENT = "GetElements"

export const ROUTE_GET_ELEMENT_LIST = "GetElementsList"


export const ROUTE_ANALYSIS = "MAnalysis"
