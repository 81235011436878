import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export default function OcrxTabList({tabs}) {
  const [value, setValue] = useState('0');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="Tabs for OCRx SPARQL">
            {tabs.map(({name},index) => <Tab key={index} label={name} value={index.toString()}/>)}
          </TabList>
        </Box>
        {tabs.map(({component},index) => (
            <TabPanel value={index.toString()} key={index}>{component}</TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}