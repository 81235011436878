import AES from "crypto-js/aes"
import {v4 as uuidv4} from "uuid"
const SECRET_KEY = "haha"
export const fetchWithHooks = async (fetchMethod,setData,setHasData) => {
    const [data, hasData] = await fetchMethod()
    setData(data)
    setHasData(hasData)
}

export const dc = (object,mappingFn) => {
    const newObj = {}
    for (const [key,val] of Object.entries(object)){
        const [newK, newV] = mappingFn(key,val)
        newObj[newK] = newV
    }
    return newObj
}
const generateAuthToken = () => {
    const unixTime = ~~(Date.now()/1000)
    const nonce = uuidv4()
    const token = AES.encrypt(`${nonce} ${unixTime}`,SECRET_KEY)
    return token
}
export const authenticatedFetch = (url,params) => {
    const token = generateAuthToken()
    const authHeader = {"headers" : {"Authorization" : `Bearer ${token}`}}
    const newParams = Object.assign({},params,authHeader)
    return fetch(url,newParams)
}