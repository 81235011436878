import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const Loading = () => {
    return (
        <div
            style={{
                width: "20%",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{ marginBottom: "2rem" }}>
                <CircularProgress />
            </div>
            <div>Currently loading...</div>
        </div>
    );
};

export default Loading;
