import React, {useState, useRef} from "react"
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import {Completer} from "../components/Completer"
import { useAuth0 } from "@auth0/auth0-react"
import { Typography } from "@mui/material"
import TextField from '@mui/material/TextField';
import { submitAddDrugRequest } from "../api/userManagement"
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
    containerForm: {
        width: "85%",
        maxWidth: "800px",
        margin: "auto",
        marginBottom: "3rem",
    },

    header: {
    },
    padded: {
        marginBottom: "1.5rem",
        display: "flex",
        justifyContent: "space-between",
        "& > * ": {
            flexGrow: 1,
            marginRight: "2rem",
        },
        "& > *:last-child": {
            marginRight: "0rem",
        },
    },
});

function displayAlert(status, successPrompt, errorPrompt) {
    switch (status) {
        case 0:
            return null
        case 1:
            return <Alert severity="success">{successPrompt}</Alert>
        case 2:
            return <Alert severity="error">{errorPrompt}</Alert>
        default:
            return null
    }
}
const DemoForm = () => {
    const classes = useStyles();
    const [status, setStatus] = useState(0)
    const [invalid, setInvalid] = useState(false)
    const userInput = useRef({});
    const createInputHandler = (name) => (value) => {
        userInput.current[name] = value;
    };
    const { t } = useTranslation()
    const tn = (key) => t(key, { ns: "request-drug" })
    const tn_comp = (key) => t(key,{ns : "PaperRx"})
    const handleSubmit = async (e) => {
        e.preventDefault()
        const formInvalid = !e.currentTarget.checkValidity()
        setInvalid(formInvalid)
        setTimeout(() => {
            setInvalid(false)
        }, 3000)
        if (formInvalid) {
            return
        }
        const data = new FormData(e.currentTarget)
        const body = Object.fromEntries(data.entries())
        body['active_ingredients'] = JSON.stringify(userInput.current['active_ingredients'])
        const isOk = await submitAddDrugRequest(body)
        setStatus(isOk ? 1 : -1)
        setTimeout(() => {
            setStatus(0)
        }, 3000)
    }
    return (
        <div className={classes.containerForm}>
            <h1 className={classes.header}>{tn("get-access-title")}</h1>
            <p>{tn("get-access-subtitle")}</p>
            <div>

                <form onSubmit={handleSubmit} noValidate>

                    <div className={classes.rowForm}>
                        <Typography variant="h5" gutterBottom>{tn("User Information")}</Typography>
                        <TextField
                            name="first_name"
                            label={tn("First Name")}
                            variant="outlined"
                            className={classes.padded}
                            fullWidth
                            required
                        />
                        <TextField
                            name="last_name"
                            label={tn("Last Name")}
                            required
                            variant="outlined"
                            className={classes.padded}
                            fullWidth
                        />
                    </div>
                    <div className={classes.rowForm}>
                        <TextField
                            required
                            name="email"
                            type="email"
                            label={tn("Email Address")}
                            variant="outlined"
                            className={classes.padded}
                            fullWidth
                        />
                    </div>
                    <div className={classes.rowForm}>
                        <TextField
                            name="institution"
                            required
                            label={tn("Institution/Company")}
                            variant="outlined"
                            className={classes.padded}
                        />
                    </div>
                    <br />
                    <Typography variant="h5" gutterBottom>{tn("Requested Drug Information")}</Typography>

                    <div className={classes.rowForm}>
                        {/* <TextField
                            name="active_ingredients"
                            label={tn("Active ingredients")}
                            variant="outlined"
                            className={classes.padded}
                            fullWidth
                            required
                        /> */}

                <Completer
                    multiple
                    required={true}
                    id="active_ingredients"
                    label={tn("Active ingredients")}
                    url="completeSubstance"
                    helperText={tn_comp("subExample")}
                    updateInput={createInputHandler("active_ingredients")}
                    className={classes.padded}
                    dontShowHelper
                    freeSolo
                />
                        <TextField
                            name="basis_of_strength"
                            label={tn("Basis of strength")}
                            required
                            variant="outlined"
                            className={classes.padded}
                            fullWidth
                        />
                    </div>
                    <div className={classes.rowForm}>
                        <TextField
                            required
                            name="strength"
                            label={tn("Strength")}
                            variant="outlined"
                            className={classes.padded}
                            fullWidth
                        />
                    </div>
                    <div className={classes.rowForm}>
                        <TextField
                            required
                            name="distinction"
                            label={tn("Distinction")}
                            variant="outlined"
                            className={classes.padded}
                            fullWidth
                        />
                    </div>
                    <div className={classes.rowForm}>
                        {/* <TextField
                            name="form"
                            required
                            label={tn("Form")}
                            variant="outlined"
                            className={classes.padded}
                        /> */}

                <Completer
                    id="form"
                    required
                    label={tn_comp("form")}
                    url="completeForm"
                    helperText={tn_comp("formExample")}
                    updateInput={createInputHandler("form")}
                    className={classes.padded}
                    dontShowHelper={true}
                    freeSolo
                />
                    </div>
                    <div className={classes.rowForm}>
                        {/* <TextField
                            name="route_of_administration"
                            label={tn("Route of administration")}
                            variant="outlined"
                            required
                            className={classes.padded}
                        /> */}

                <Completer
                    id="route_of_administration"
                    label={tn_comp("roa")}
                    url="completeRoute"
                    helperText={tn_comp("roaExample")}
                    required
                    dontShowHelper={true}
                    className={classes.padded}
                    updateInput={createInputHandler("route")}
                    freeSolo
                />
                    </div>

                    <div className={classes.rowForm}>
                        <TextField
                            name="reason"
                            label={tn("Reason/Purpose")}
                            variant="outlined"
                            required
                            rows={6}
                            fullWidth
                            multiline={true}
                        />
                    </div>
                    <Button variant="outlined" style={{ marginTop: "1rem" }} type="submit">
                        {tn("Request Access")}
                    </Button>
                </form>
                <br />
                {invalid && <Alert severity="warning">{t("please-complete",{ns: "request-access"})}</Alert>}
                {displayAlert(status, t("submit-success", {ns : "request-access"}), t("submit-error", {ns: "request-access"}))}
            </div >
        </div >
    );
};

export const RequestAccessPage = () => {
    return (
        <DemoForm></DemoForm>
    )
}

export default RequestAccessPage