import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Link } from "react-router-dom"
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { getUserInfo } from "../api/userManagement";
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

function UserCard() {
    const classes = useStyles()
    const { user } = useAuth0()
    const {t} = useTranslation()
    const tn = (key) => t(key,{ns : "Profile"})
    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography color="textPrimary" variant="h5" gutterBottom>
                    {tn("User Information")}
                </Typography>
                <Typography color="textSecondary">
                    {tn("Name")} : {user.name} <br />
                    {tn("Email")} : {user.email}
                </Typography>
            </CardContent>
        </Card>
    )
}

function ApiCard() {
    const classes = useStyles();
    const token = "eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY0OTk2ODEyOCwiaWF0IjoxNjQ5OTY4MTI4fQ.R5kM8dwOOA7Ex5_cEO1RiJP_7jbITQ0VJibF5xVbybc"
    const tokenSnippet = token.slice(0, 6)
    const [showHasCopied, setShowHasCopied] = useState(false);
    const {t} = useTranslation()
    const tn = (key) => t(key,{ns : "Profile"})
    const copyToClipboard = () => {
        copy(token);
        setShowHasCopied(true);
        setTimeout(() => {
            setShowHasCopied(false);
        }, 1000)
    }

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography color="textPrimary" variant="h5" gutterBottom>
                    {tn("REST API")}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    <Trans i18nKey="rest-approve" components={{newline : <br/>}} ns="Profile"/>
                </Typography>
                <Typography variant="body" component="p"> 
                    {tn("Your token:")} {tokenSnippet}...
                </Typography>
                <Typography color="textSecondary">
                    {showHasCopied && tn("token-copied")}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" variant="outlined" onClick={copyToClipboard}>{tn("token-copy")}</Button>
            </CardActions>
        </Card>
    );
}

function PendingAPICard({ status }) {
    const classes = useStyles()
    const {t} = useTranslation()
    const tn = (key) => t(key,{ns : "Profile"})
    const message = status === 'P' ? tn("rest-pending") : tn("rest-denied")
    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography color="textPrimary" variant="h5" gutterBottom>
                    {tn("REST API")}
                </Typography>
                <Typography color="textSecondary">
                    {message}
                </Typography>
            </CardContent>
        </Card>
    );
}

function NoAccessAPICard() {
    const classes = useStyles()
    const {t} = useTranslation()
    const tn = (key) => t(key,{ns : "Profile"})
    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography color="textPrimary" variant="h5" gutterBottom>
                    {tn("REST API")}
                </Typography>
                <Typography color="textSecondary">
                    {tn("rest-secondary")}
                </Typography>
            </CardContent>
            <CardActions>

                <Link to="/request-access" style={{ display: "block", textDecoration: "none" }}>
                    <Button size="small" variant="outlined">
                        {tn("Request Access")}
                    </Button>
                </Link>

            </CardActions>
        </Card >
    );
}


function getApiComponent(data, hasData) {
    if (!hasData || !data['exists']) {
        return <NoAccessAPICard></NoAccessAPICard>
    } else if (data['user']['status'] === 'P' || data['user']['status'] === 'R') {
        return <PendingAPICard status={data['user']['status']}></PendingAPICard>
    } else {
        return <ApiCard userInfo={data.user}></ApiCard>
    }
}
export default function Profile() {
    const { user } = useAuth0()
    const [data, setData] = useState(null)
    const [hasData, setHasData] = useState(false)
    useEffect(() => {
        (async function () {
            const [userInfo, hasInfo] = await getUserInfo(user.email)
            setData(userInfo)
            setHasData(hasInfo)
        })()
    }, [])
    const apiComponent = getApiComponent(data, hasData)
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <main>
                    <UserCard></UserCard>
                    <br />
                    {apiComponent}
                </main>
            </Container>
        </React.Fragment>
    );
}
