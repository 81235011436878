import React, { useEffect } from "react";
import { ResultsTable } from "./ResultsTable";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import {DontSeeDrug} from "./DontSeeDrug"

const useStyles = makeStyles({
    tableContainer: {
        display: "flex",
        flexDirection: "column",
        "& > *": {
            marginBottom: "2rem",
            width: "90%",
            maxWidth: "1600px",
            //minWidth : "500px",
            margin: "auto",
        },
    },
});


//// TODO: needs preprocessing
//const substancesHeadingsDefMappings = [
//{field : "property", title : "Property", description : "Name of property queried. Example : (ID, Source, SourceType)"},
//{field : "substance", title : "Substance", description : "Queried substance"},
//{field : "form", title : "Form", description : "Form of the drug"},
//{field : "route" , title : "Route of Administration", description : "The route of administration of the drug"}
//]

//const externalMappings = [
//{field : "XREF", title : "External ID", description : "ID from external source"},
////{field : "RxCui", title : "RxNorm identifier", description : "The RXNorm concept unique identifier"},
//{field : "Source", title : "External Source", description : "Abbreviation of external source"},
//{field : "Type", title : "Type from External Source", description : "The type abbreviation from the external source"}
//]

const mergeMappings = (data) => {
    const parsedMappings = data.map((row) => {
        const { Mapping, ...rest } = row;
        const cleanedMappings = Mapping.map(({ XREF, Type, Source }) => {
            return ` ${Type} ID: ${XREF} (${Source})`;
        });
        return { ...rest, Mapping: cleanedMappings };
    });
    return parsedMappings;
};

export const Results = ({ data }) => {
    const { t } = useTranslation()
    const tn = (key) => t(key, { ns: "PaperRx" })
    const getFrom = (list) => (index) => list[index];
    const clinicalHeadingMappings = [
        { field: "RxCui", title: "ID", description: tn("rxcuiDescription")},
        {
            field: "labels",
            title: tn("Drug Labels"),
            description: tn("Alllabel"),
        },
        {
            field: "BrandedIDs",
            title: tn("DIN identifiers"),
            description: tn("All DIN identifiers for drug"),
        },
        // IMPORTANT : add external identifier
        {
            field: "Mapping",
            title: tn("External Identifiers"),
            description: tn("External identifiers for the same drug"),
        },
    ];

    const importantClinicalHeadingMappings = [0, 1].map(
        getFrom(clinicalHeadingMappings)
    );

    const defHeadingMappings = [
        {
            field: "TTY",
            title: tn("defTitle0"),
            description: tn("defDescription0"),
        },
        { field: "RxCui", title: "ID", description: tn("rxcuiDescription") },
        {
            field: "labels",
            title: tn("labels"),
            description: tn("labelsDescription"),
        },
        {
            field: "Mapping",
            title: tn("extId"),
            description: tn("extId"),
        },
    ];

    const importantDefHeadingMappings = [0, 1].map(getFrom(defHeadingMappings));
    const classes = useStyles();
    const clinicalData = data["clinic"];
    const withMapClinicalData = mergeMappings(clinicalData);
    const definitionalData = data["def"];
    const unTranslatedWithMapDefinitionalData = mergeMappings(definitionalData);
    const withMapDefinitionalData = unTranslatedWithMapDefinitionalData.map(el => {
        const {TTY, ...stuff} = el
        return {TTY : tn(TTY.trim()), ...stuff}
    })
    
    useEffect(() => {
        document.querySelector("#tableTop").scrollIntoView({ behavior: 'smooth' })
    }, [])
    return (
        <div id="tableTop">
            <div className={classes.tableContainer}>
                <ResultsTable
                    data={withMapDefinitionalData}
                    headingMappings={defHeadingMappings}
                    importantHeadings={importantDefHeadingMappings}
                    title={tn("Your query")}
                />
                <DontSeeDrug></DontSeeDrug>
                <ResultsTable
                    data={withMapClinicalData}
                    headingMappings={clinicalHeadingMappings}
                    importantHeadings={importantClinicalHeadingMappings}
                    title={tn("Clinical drugs")}
                />
            </div>
        </div>
    );
};
