// src/components/signup-button.js

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const SignupButton = () => {
    const { loginWithRedirect } = useAuth0();
    const {t} = useTranslation()
    return (
        <Button variant='outlined'
            onClick={() =>
                loginWithRedirect({
                    screen_hint: "signup",
                })
            }
        >
            {t("Sign Up")}
        </Button>
    );
};

export default SignupButton;
