import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, useMediaQuery } from "@mui/material";
import myimage from "../logo.png";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import AuthenticationButton from "../auth/AuthenticationButton";
import { getUserInfo } from "../api/userManagement";
import { useTranslation } from "react-i18next";
import {Select, MenuItem, Box} from "@mui/material";
const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "1.8rem",
        width: "95%",
        margin: "auto",
        marginTop: "1rem",
        fontFamily: "Montserrat",
    },
    bigContainer: {
        marginBottom: "3rem",
    },
    brandName: (theme) => ({
        color: theme.palette.primary.main,
    }),
    linkContainer: {
        display: "flex",
        fontSize: "0.6em",
        flexDirection: "row",
        alignItems: "center"
    },
    link: {
        padding: "0em 0.5em",
        color: "black",
        textDecoration: "none",
        fontSize : "0.9rem",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    imageT: {
        height: "2.2em",
    },
    drawer: {
        maxHeight: "0vh",
        overflow: "hidden",
        transition: "max-height 0.1s ease-in",
        "&.slide": {
            maxHeight: "100vh",
            transition: "max-height 0.1s ease-out",
        },
    },
});
const LinksList = ({ links, linkHref, opened }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <div
            className={
                opened ? [classes.drawer, "slide"].join(" ") : classes.drawer
            }
        >
            <Divider />

            <List>
                {links.map((link, i) => (
                    <div key={link} style={{ width: "95%", margin: "auto" }}>
                        <ListItem>
                            <a
                                className={classes.link}
                                href={linkHref[i]}
                                style={{ textAlign: "center" }}
                            >
                                <ListItemText>{link}</ListItemText>
                            </a>
                        </ListItem>
                    </div>
                ))}
                <ListItem>
                    <div
                        style={{
                            width: "95%",
                            margin: "auto",
                            textAlign: "center",
                        }}
                    >

                        <AuthenticationButton></AuthenticationButton>
                    </div>
                </ListItem>
                <ListItem>
                </ListItem>
            </List>
            <Divider />
        </div>
    );
};

const TranslateLang = () => {
    const {i18n} = useTranslation()
    const [lang, setLang] = React.useState(i18n.language)
    const handleChange = (e) => {
        i18n.changeLanguage(e.target.value)
        setLang(e.target.value)
    }
    return (
        <Box sx={{minWidth : 120}}>
    <Select onChange={handleChange} value={lang}>
        <MenuItem value={"en"}>EN</MenuItem>
        <MenuItem value={"fr"}>FR</MenuItem>
    </Select>
        </Box>
    )
}
export const NavBar = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [opened, setOpened] = useState(false);
    const { user, isAuthenticated } = useAuth0();
    // const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const matches = useMediaQuery(theme.breakpoints.down("xl"))
    // const matches = isAuthenticated ? largeMatches : mdMatches
    const {t} = useTranslation()
    const tn = (key) => t(key,{ns : "navbar"})
    const rawLinks = ["about", "paperrx", "our team", "contact us", "NER playground"];
    const links = rawLinks.map(link => tn(link))
    const linkHref = [
        "/About",
        "/PaperRx",
        "https://www.labtns.ca/team/",
        "https://www.labtns.ca/contact-us/",
        "/NER"
    ];
    if (isAuthenticated) {
        links.push(tn("Request Access"))
        linkHref.push('/request-access')
        links.push(tn("Download OCRx"));
        linkHref.push("/download");
        links.push("SPARQL")
        linkHref.push("/sparql")
        links.push(tn("Profile"));
        linkHref.push("/Profile");
        links.push(tn("Request a Drug"));
        linkHref.push("/request-drug");
        links.push(tn("REST Documentation"));
        linkHref.push("/rest-documentation");
    }
    const toggleDrawer = () => {
        setOpened((op) => !op);
    };
    const AdaptMenuIcon = opened ? MenuOpenIcon : MenuIcon;
    // console.log(theme);
    return (
        <div className={classes.bigContainer}>
            <div className={classes.container}>
                <img
                    src={myimage}
                    alt="OCRx logo"
                    title="OCRx logo"
                    className={classes.imageT}
                />

                {matches ? (
                    <div style={{display : "flex"}}>
                    <TranslateLang></TranslateLang>
                    <Button onClick={toggleDrawer}>

                        <AdaptMenuIcon htmlColor="#000"
                            style={{ height: "1.5em", width: "1.5em" }}
                        ></AdaptMenuIcon>
                    </Button>
                    </div>
                ) : (
                    <div>
                        <div className={classes.linkContainer}>
                            {links.map((link, i) => (
                                <div key={link} className={classes.link}>
                                    <a
                                        className={classes.link}
                                        href={linkHref[i]}
                                    >
                                        {link}
                                    </a>
                                </div>
                            ))}
                            <TranslateLang></TranslateLang>
                            <AuthenticationButton></AuthenticationButton>
                        </div>
                    </div>
                )}
            </div>
            {matches && <LinksList {...{ links, linkHref, opened }} />}
        </div>
    );
};
