// src/auth/protected-route.js

import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../components/Loading';

const ProtectedRoute = ({ component }) => {
    const Cp = withAuthenticationRequired(component, {
            onRedirecting: () => <Loading />,
        })
    return <Cp/>
};

export default ProtectedRoute;
