import React, { useEffect } from "react";
import { Banner } from "./Banner";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CardActions from "@mui/material/CardActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import myimageModel from "../OCRxModel.png";
import myimageExemple from "../ExampleSubstanceEN.svg";
import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation, Trans } from "react-i18next";
const useStyles = makeStyles({
  label: {
    fontWeight: 800,
    fontSize: "3rem",
    marginBottom: "1.5rem",
  },

  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    // textAlign : "center",
    //background : "teal",
    // height : "50vmin",
    marginBottom: "2rem",
  },
  card: {
    fontFamily: "Montserrat",
    width: "95%",
    //maxWidth: "1500px",

    margin: "auto",
    padding: "2rem",
    borderRadius: "10px",
  },
  cardContent: {
    // height: 1500
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    height: 320,
    width: 300,
  },
  Media: {
    // height : "20em"
  },
  imageT: {
    height: "20em",
  },
  labelDescription: {
    fontSize: "1.5rem",
  },
  indentTitle: {
    marginLeft: "100px",
  },
  Aligncentre: {
    textAlign: "center",
  },
  indentParagraph: {
    marginLeft: "50px",
  },
});

export const AboutOCRx = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { t  } = useTranslation()
  const tn = (key) => t(key, { ns: "about" })

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const dpdLink = <a href="https://www.canada.ca/en/health-canada/services/drugs-health-products/drug-products/drug-product-database.html" target="_blank" rel="noreferrer" ></a>
  const ccddLink = <a href="https://infocentral.infoway-inforoute.ca/en/standards/canadian/ccdd" target="_blank" rel="noreferrer" ></a>
  const tnsLink = <a href="http://www.labtns.ca/en/" target="_blank" rel="noreferrer"> </a>
  const dinLink = <a href="https://www.canada.ca/en/health-canada/services/drugs-health-products/drug-products/fact-sheets/drug-identification-number.html"></a>
  const rxnormLink = <a href="https://mor.nlm.nih.gov/RxNav/"></a>
  const imdpLink = <a href="https://www.iso.org/fr/news/ref2234.html" target="_blank" rel="noreferrer" ></a>
  return <>
    <CssBaseline />
    <Banner></Banner>

    <Grid id="Whatis" container className={classes.container}>
      <Grid xs={12} sm={11} md={10} lg={8} xl={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography
              className={classes.Aligncentre}
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
            >
              {tn("whatTitle")}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              <Trans i18nKey="ocrxSubtitle1" ns="about" components={{tns : tnsLink}}/> <br /> <br />
              {tn("ocrxSubtitle2")}
              <br />
              <div className={classes.indentParagraph}>
                <ol>
                  <li>
                    <Trans i18nKey="item1" ns="about" 
                    components={{dpd : dpdLink}}/>
                  </li>
                  <li>
                    <Trans i18nKey="item2" ns="about" components={{ccdd : ccddLink}}/>
                  </li>
                </ol>
              </div>
              {tn("firstVersionText")}
              <br />
              <br />
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Definitionnal elements</TableCell>
                    <TableCell align="center">Definition</TableCell>
                    <TableCell align="center">Example</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow key="1">
                    <TableCell component="th" scope="row">
                      Substance
                    </TableCell>

                    <TableCell align="left">
                      Chemical product playing a role of active ingredient,
                      active moiety or reference for strength in clinical
                      drugs
                    </TableCell>
                    <TableCell align="left">
                      3000001547- "ATORVASTATIN"
                    </TableCell>
                  </TableRow>

                  <TableRow key="2">
                    <TableCell component="th" scope="row">
                      Strength
                    </TableCell>
                    <TableCell align="left">Drug strength</TableCell>
                    <TableCell align="left">S10018- "5 G/1 L" </TableCell>
                  </TableRow>

                  <TableRow key="3">
                    <TableCell component="th" scope="row">
                      Route of administration
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      Path by which a drug is designed to be taken
                    </TableCell>
                    <TableCell align="left">
                      4100000004- "OPHTHALMIC"
                    </TableCell>
                  </TableRow>

                  <TableRow key="4">
                    <TableCell component="th" scope="row">
                      Pharmaceutical form
                    </TableCell>
                    <TableCell align="left">
                      Manufactured dose form defined using a basic dose form a
                      route of administration and sometimes a distinction
                    </TableCell>
                    <TableCell align="left">
                      4002225239- "PATCH (8HR EXTENDED RELEASE) IN
                      TRANSDERMAL"
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/*              <CardMedia component="img" image={myimage} title="" />
TROUVER UNE IMAGE POUR DECRIRE OCRX?
*/}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>

    <Grid id="Whatpurpose" container className={classes.container}>
      <Grid xs={12} sm={11} md={10} lg={8} xl={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography
              className={classes.Aligncentre}
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
            >
              {tn("whyOcrx")}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              <Trans i18nKey="dinText" ns= "about" components={{din : dinLink, ccdd : ccddLink, dpd : dpdLink, rxnorm : rxnormLink, newline : <br/>}}/>
              <div className={classes.indentParagraph}>
                <Trans i18nKey="dinListText" ns="about" components={{tul : <ul></ul>, tli : <li></li>,imdp : imdpLink}}/>
              </div>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>

    <Grid id="WhatIN" container className={classes.container}>
      <Grid xs={12} sm={11} md={10} lg={8} xl={6}>
        <Card className={classes.card}>
          <CardContent>
            <Typography
              className={classes.Aligncentre}
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
            >
              {tn("whatInOcrx")}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              <Trans i18nKey="whatInOcrxText" ns="about" components={{tbr : <br/>}}/>
<br/><br/>
    {tn("access-ocrx")}
            </Typography>
          </CardContent>

          <CardActions disableSpacing style={{ display: "flex", flexDirection: "column" }}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              size="large">
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Typography
              className={classes.Aligncentre}
              variant="subtitle1"
              paragraph
            >
              <b>
                {" "}
                Preview of an OCRx drug in{" "}
                <a href="https://protege.stanford.edu/">Protégé</a>{" "}
              </b>
            </Typography>
            <CardMedia component="img" image={myimageModel} title="" />

            <CardContent className={classes.cardContent}>
              <Typography
                className={classes.Aligncentre}
                variant="subtitle1"
                paragraph
              >
                <br />
                <br />
                <b>
                  OCRx carries out a normalization of drug names in English
                  and in French.
        {tn("normalization")}
                </b>
              </Typography>
            </CardContent>
            <CardMedia component="img" image={myimageExemple} title="" />
          </Collapse>
        </Card>
      </Grid>
    </Grid>
  </>;
};
