import React from "react"
import { Link } from "@mui/material"
import { Typography } from "@mui/material"
import { useTranslation, Trans } from "react-i18next"
export const DontSeeDrug = () => {
    const {t} = useTranslation()
    const tn = (key) => t(key,{ns : "request-drug"})
    const drugLinkComponent = <Link href="/request-drug">here</Link>
    return <div style={{ width: "95%", margin: "auto", textAlign: "center" }}>
        <Typography variant="h6">{tn("Don't see your drug?")}</Typography><br /><Typography variant="body"> 
        
              <Trans i18nKey="add-here" ns="request-drug" components={{drugLink: drugLinkComponent}}/>
        {/* You must create an OCRx account before filling out the drug request form. Add it to OCRx's database  */}
        </Typography>
    </div>
}