import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
    Divider,
    useTheme,
    useMediaQuery,
    Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { List, ListItem } from "@mui/material";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    table: {
        minWidth: 200,
        //tableLayout: "fixed",
    },

    label: {
        fontWeight: 600,
        fontSize: "2rem",
        textAlign: "center",
        marginTop: "3rem",
    },
    helpText: {
        display: "flex",
        alignItems: "center",
    },
});

const useCollapsibleList = makeStyles((theme) => ({
    button: {
        color: theme.palette.primary.main,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
    },
}));
const CollapsibleList = ({ values }) => {
    let limit = 2;
    const [opened, setOpened] = useState(false);
    const count = values.length - limit;
    const theme = useTheme();
    const classes = useCollapsibleList(theme);
    const centeredStyle = { display: "flex", justifyContent: "center" };
    const displayList = (list) => {
        return list.map((value, i) => (
            <React.Fragment key={i}>
                {i !== 0 && <Divider />}
                <ListItem button style={centeredStyle}>
                    {" "}
                    {value}{" "}
                </ListItem>
            </React.Fragment>
        ));
    };
    if (values.length === 1) {
        return values[0];
    }
    if (count <= 0) {
        return <List>{displayList(values)}</List>;
    }

    if (opened) {
        return (
            <List>
                {displayList(values)}
                <Divider />
                <ListItem
                    button
                    onClick={() => setOpened(false)}
                    style={centeredStyle}
                    className={classes.button}
                >
                    {" "}
                    Less{" "}
                </ListItem>
            </List>
        );
    } else {
        return (
            <List>
                {displayList(values.slice(0, limit))}
                <Divider />
                <ListItem
                    button
                    onClick={() => setOpened(true)}
                    className={classes.button}
                >
                    More
                </ListItem>
            </List>
        );
    }
};

const MobileRow = ({ row, importantHeadings, excludedHeadings }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowDownIcon />
                        ) : (
                            <KeyboardArrowRightIcon />
                        )}
                    </IconButton>
                </TableCell>
                {importantHeadings.map((headingMap) => {
                    const { field } = headingMap;
                    return (
                        <TableCell align="center">
                            {displayValue(row[field])}
                        </TableCell>
                    );
                })}
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            style={{ textAlign: "center", marginTop: "1rem" }}
                        >
                            Additional Information
                        </Typography>
                        <Box margin={1}>
                            <Table size="small">
                                {excludedHeadings.map(({ field, title }) => (
                                    <TableRow>
                                        <TableCell>{title}:</TableCell>
                                        <TableCell>
                                            {displayValue(row[field])}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const MobileResultTableRows = ({
    headingMappings,
    rows,
    importantHeadings,
}) => {
    const includedMappings = new Set(importantHeadings.map(val => val['title']));
    const excludedHeadings = headingMappings.filter(
        (obj) => !includedMappings.has(obj) && !includedMappings.has(obj['title'])
    );
    return rows.map((row, i) => (
        <MobileRow key={i} {...{ row, importantHeadings, excludedHeadings }} />
    ));
};
export const MobileResultsTable = ({
    data,
    title,
    headingMappings,
    importantHeadings,
}) => {
    // mobile table should have collapsible component to it
    // console.log(importantHeadings)
    const classes = useStyles();
    importantHeadings = importantHeadings.map(string => ({field: string, title: string, description : string}))
    const rows = data.filter(({ RxCui }) => RxCui !== "Nothing");
    // const rows = data
    return (
        <div>
            <div className={classes.label}>{title}</div>
            <Table
                className={classes.table}
                aria-label="simple table"
                stickyHeader={true}
                size="sm"
            >
                <TableHead>
                    <TableRow>
                    <TableCell/>
                        <ResultTableHeadings
                            headingMappings={importantHeadings}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <MobileResultTableRows
                        headingMappings={headingMappings}
                        rows={rows}
                        importantHeadings={importantHeadings}
                    />
                </TableBody>
            </Table>
        </div>
    );
};

const displayValue = (displayedValue) => {
    if (Array.isArray(displayedValue)) {
        displayedValue = <CollapsibleList values={displayedValue} />;
    }
    return displayedValue;
};

const ResultTableRows = ({ headingMappings, rows }) => {
    return rows.map((row, i) => {
        return (
            <TableRow key={i}>
                {" "}
                {headingMappings.map((headingMap) => {
                    const { field } = headingMap;
                    return (
                        <TableCell align="center">
                            {displayValue(row[field])}
                        </TableCell>
                    );
                })}{" "}
            </TableRow>
        );
    });
};

const ResultTableHeadings = ({ headingMappings }) => {
    return headingMappings.map((headingMap) => {
        const { title, description } = headingMap;
        return (
            <TableCell align="center" key={title}>
                <div style={{ marginRight: "0.2rem" }}>
                    {title}
                    <Tooltip enterTouchDelay={0} title={description}>
                        <div>
                            <HelpOutlineIcon />
                        </div>
                    </Tooltip>
                </div>
            </TableCell>
        );
    });
};

export const DesktopResultsTable = ({
    data,
    title,
    headingMappings,
    importantHeadings,
}) => {
    const classes = useStyles();
    if (data.length === 0) {
    }
    const rows = data.filter(({ RxCui }) => RxCui !== "Nothing");
    // const rows = data
    return (
        <div>
            <div className={classes.label}>{title}</div>
            <Table
                className={classes.table}
                aria-label="simple table"
                stickyHeader={true}
            >
                <TableHead>
                    <TableRow>
                        <ResultTableHeadings
                            headingMappings={headingMappings}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <ResultTableRows
                        headingMappings={headingMappings}
                        rows={rows}
                        importantHeadings={importantHeadings}
                    />
                </TableBody>
            </Table>
        </div>
    );
};

export const ResultsTable = (props) => {
    const theme = useTheme();
    const { data } = props;
    const {t} = useTranslation()
    const tn = (key) => t(key,{ns : "PaperRx"})
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const { headingMappings: oldHeadingMappings , importantHeadingMappings : oldImportantHeadingMappings, ...theRest } = props
    const headingMappings = oldHeadingMappings.map(rHeadingMap => {
        let headingMap = rHeadingMap
        if (typeof headingMap === "string" || headingMap instanceof String) {
            headingMap = { title: rHeadingMap, description: rHeadingMap, field: rHeadingMap }
        }
        return headingMap
    })
    if (data.length === 0) {
        return <div style={{textAlign : "center",marginTop : "1rem"}}>
            <Typography variant="subtitle">{tn("no-drugs")}</Typography>
        </div>
    }
    return matches ? (
        <MobileResultsTable {...theRest} headingMappings={headingMappings} />
    ) : (
        <DesktopResultsTable {...theRest} headingMappings={headingMappings} />
    );
};
