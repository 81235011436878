import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider,
} from "@mui/material/styles";
import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";
import { AboutOCRx } from "./components/AboutOCRx";
import Profile from "./components/ApiProfile";
import { Footer } from "./components/Footer";
import {NERPlayground} from "./components/NERPlayground"
import DownloadPage from "./components/DownloadPage"
import { NavBar } from "./components/NavBar";
import { NotFound } from "./components/NotFound";
import { PaperRx } from "./components/PaperRx";
import RequestAccessPage from "./components/RequestAccess";
import RequestDrug from "./components/RequestDrug";
import { CodeEditor } from "./components/CodeEditor";
import { RestDocumentation } from "./components/RestDocumentation";

const theme = createTheme({
    palette: {
        // primary: {
        //     // main: "#65a7b5",
        // },
        secondary: {
            main: "#65a7b5",
        },
    },
    typography: {
        fontFamily: ["Montserrat"],
    },
});
function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <NavBar />
                <Routes>
                    <Route path="/PaperRx" element={<PaperRx />} />
                    <Route path="/about" element={<AboutOCRx />} />
                    <Route
                        path="/request-access"
                        element={
                            <ProtectedRoute component={RequestAccessPage} />
                        }
                    />
                    <Route
                        path="/profile"
                        element={<ProtectedRoute component={Profile} />}
                    />
                    <Route
                        path="/sparql"
                        element={<ProtectedRoute component={CodeEditor} />}
                    />
                    <Route
                        path="/rest-documentation"
                        element={
                            <ProtectedRoute component={RestDocumentation} />
                        }
                    />
                    <Route
                        path="/request-drug"
                        element={<ProtectedRoute component={RequestDrug} />}
                    />
                    <Route path="/NER" element={<NERPlayground/>}/>
                    <Route path="/Download" element={<ProtectedRoute component={DownloadPage}/>}/>
                    <Route path="/" element={<PaperRx />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
