import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const LoginButton = () => {
    const obj = useAuth0();
    const { loginWithRedirect } = obj;
    const {t} = useTranslation()
    return (
        <Button variant="outlined" onClick={() => loginWithRedirect()}>
            {t("Log In")}
        </Button>
    );
};

export default LoginButton;
