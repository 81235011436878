import React from "react"
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles({
  footer: {
    marginTop: "2rem",
    textAlign: "center"
  }
})
export const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      © Université de Montréal, <a href="http://www.labtns.ca/en/" target="_blank" rel="noreferrer">LabTNS</a>, {new Date().getFullYear()}
    </footer>
  )
}
