import React, { useRef, useState } from "react";
import { Button, Alert } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Completer } from "./Completer";
import { BACKEND_URl, ROUTE_ANALYSIS, ROUTE_GET_ELEMENT_LIST } from "../Config";
import { useTranslation } from "react-i18next";
import { authenticatedFetch } from "../helpers";

const submitFormList = async (formData) => {
    const response = await fetch(BACKEND_URl + "/" + ROUTE_GET_ELEMENT_LIST, {
        method: "POST",
        body: formData,
    });
    const idsList = await response.json();
    //FIXME to verify
    // console.log(response)
    // console.log(idsList) // voir si subtance est une liste de 1 ou plusieurs valeurs
    return idsList;
};

const submitAnalysis = async (formData) => {
    const response = await authenticatedFetch(BACKEND_URl + "/" + ROUTE_ANALYSIS, {
        method: "POST",
        body: formData,
    });
    if (response.ok) {
        const analysisData = await response.json();
        return [analysisData, true];
    } else {
        return [[], false];
    }
};

const useStyles = makeStyles({
    container: {
        width: "70%",
        minWidth: "300px",
        maxWidth: "700px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        "& > *": {
            marginBottom: "2rem",
        },
    },
    submitButton: {
        width: "30%",
        margin: "auto",
    },
    message: {},
});
export const OcrForm = ({ onAnalysis }) => {
    const classes = useStyles();
    const userInput = useRef({});
    const [invalidForm, setInvalidForm] = useState(false);

    const createInputHandler = (name) => (value) => {
        userInput.current[name] = value;
    };
    const handleSubmit = async (e) => {
        window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
        e.preventDefault();
        // console.log(userInput.current);
        const isInvalid =
            !userInput.current["substance"] ||
            userInput.current["substance"].length === 0;
        setInvalidForm(isInvalid);
        setTimeout(() => {
            setInvalidForm(false);
        }, 3000);
        // let formData = new FormData();
        // formData.append("substance", userInput.current["substance"]);
        // formData.append("form", userInput.current["form"]);
        // formData.append("route", userInput.current["route"]);

        // // console.log(...formData)

        // const idsList = await submitFormList(formData);
        const analysisData = await submitIds(userInput.current);
        onAnalysis(analysisData);
    };

    const submitIds = async (data) => {
        let formData = new FormData();

        formData.append(
            "substance",
            data.substance.map((el) => el.id)
        );
        if (data.form) formData.append("form", data.form.id);
        if (data.route) formData.append("route", data.route.id);
        return await submitAnalysis(formData);
    };

    // const submitIds = async ({substanceId,routeId,formId}) => {
    //   let formData = new FormData()
    //   formData.append("form", formId)
    //   formData.append("substance",substanceId)
    //   formData.append("route",routeId)
    //   console.log("TOTOTATA")
    // console.log(...formData)
    //   return await submitAnalysis(formData)
    //
    // }
    const { t } = useTranslation();
    const tn = (key) => t(key, { ns: "PaperRx" });

    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.container}>
                <div className={classes.message}>{tn("pleasePrompt")}</div>
                {invalidForm && (
                    <Alert severity="warning">{tn("please-substance")}</Alert>
                )}
                <Completer
                    multiple
                    required={true}
                    id="substance"
                    label={tn("Substance")}
                    url="completeSubstance"
                    helperText={tn("subExample")}
                    updateInput={createInputHandler("substance")}
                />
                <Completer
                    id="form"
                    label={tn("form")}
                    url="completeForm"
                    helperText={tn("formExample")}
                    updateInput={createInputHandler("form")}
                />
                <Completer
                    id="route"
                    label={tn("roa")}
                    url="completeRoute"
                    helperText={tn("roaExample")}
                    updateInput={createInputHandler("route")}
                />
                <div className={classes.message}>{tn("bottomPrompt")}</div>
                <Button
                    type="submit"
                    variant="outlined"
                    className={classes.submitButton}
                >
                    {t("submit")}
                </Button>
                <br />
                <div>{t("must-ocrx-account", { ns: "about" })}</div>
            </div>
        </form>
    );
};
