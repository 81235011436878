import { makeStyles } from "@material-ui/styles";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import {useAuth0} from "@auth0/auth0-react"
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { submitAccessRequest } from "../api/userManagement"
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
    containerForm: {
        width: "85%",
        maxWidth: "800px",
        margin: "auto",
        marginBottom: "3rem",
    },

    header: {
    },
    padded: {
        marginBottom: "1.5rem",
        display: "flex",
        justifyContent: "space-between",
        "& > * ": {
            flexGrow: 1,
            marginRight: "2rem",
        },
        "& > *:last-child": {
            marginRight: "0rem",
        },
    },
});

function displayAlert(status,successPrompt,errorPrompt) {
    switch (status) {
        case 0:
            return null
        case 1:
            return <Alert severity="success">{successPrompt}</Alert>
        case 2:
            return <Alert severity="error">{errorPrompt}</Alert>
        default:
            return null
    }
}
const DemoForm = () => {
    const classes = useStyles();
    const [status, setStatus] = useState(0)
    const [invalid,setInvalid] = useState(false)
    const {t} = useTranslation()
    const tn = (key) => t(key,{ns : "request-access"})
    const handleSubmit = async (e) => {
        e.preventDefault()
        const formInvalid = !e.currentTarget.checkValidity()
        setInvalid(formInvalid)
        setTimeout(() => {
            setInvalid(false)
        },3000)
        if (formInvalid){
            return
        }
        const data = new FormData(e.currentTarget)
        const body = Object.fromEntries(data.entries())
        const isOk = await submitAccessRequest(body)
        setStatus(isOk ? 1 : -1)
        setTimeout(() => {
            setStatus(0)
        }, 3000)
    }
    return (
        <div className={classes.containerForm}>
            <h1 className={classes.header}>{tn("get-access-title")}</h1>
            <p>{tn("get-access-subtitle")}</p>
            <div>

                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.rowForm}>
                        <TextField
                            name="first_name"
                            label={tn("First Name")}
                            variant="outlined"
                            className={classes.padded}
                            fullWidth
                            required
                        />
                        <TextField
                            name="last_name"
                            label={tn("Last Name")}
                            required
                            variant="outlined"
                            className={classes.padded}
                            fullWidth
                        />
                    </div>
                    <div className={classes.rowForm}>
                        <TextField
                            required
                            name="email"
                            type="email"
                            label={tn("Email Address")}
                            variant="outlined"
                            className={classes.padded}
                            fullWidth
                        />
                    </div>
                    <div className={classes.rowForm}>
                        <TextField
                            name="institution"
                            required
                            label={tn("Institution/Company")}
                            variant="outlined"
                            className={classes.padded}
                        />
                    </div>
                    <div className={classes.rowForm}>
                        <TextField
                            name="reason"
                            label={tn("Reason/Purpose")}
                            variant="outlined"
                            required
                            rows={6}
                            fullWidth
                            multiline={true}
                        />
                    </div>
                    <Button variant="outlined" style={{ marginTop: "1rem" }} type="submit">
                        {tn("Request Access")}
                    </Button>
                </form>
                <br />
                {invalid && <Alert severity="warning">{tn("please-complete")}</Alert>}
                {displayAlert(status,tn("submit-success"),tn("submit-error"))}
            </div>
        </div>
    );
};

export const RequestAccessPage = () => {
    return (
        <DemoForm></DemoForm>
    )
}

export default RequestAccessPage