// src/components/authentication-button.js

import React from "react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogOutButton";
import { useAuth0 } from "@auth0/auth0-react";
import SignupButton from "./SignupButton";

const AuthenticationButton = () =>{
    const { isAuthenticated } = useAuth0();
    const loginPart = (
        <>
            <LoginButton></LoginButton>
        &nbsp;&nbsp;
            <SignupButton></SignupButton>
        </>
    );

    return isAuthenticated ? <LogoutButton /> : loginPart;
};

export default AuthenticationButton;
